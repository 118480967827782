import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SALES_COLUMNS } from 'utils/salesData';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import ValidationTable from 'components/common/GenericTable/ValidationTable';
import { UPDATE_VALIDATION } from '_redux/types/errorLogs.types';
import { UPDATE_FILE } from '_redux/types/files.types';
import { FETCH_VALIDATIONS } from '_redux/types/errorLogs.types';
import { errorLogsActions } from '_redux/slices/errorLogs.slice';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { GET_FILES } from '_redux/types/files.types';
import { filesActions } from '_redux/slices/files.slice';
import { formatDate } from 'utils';

const Validation = ({ handleTabChange, tab, tabs }) => {
  const dispatch = useDispatch();
  const validations = useSelector(errorLogsActions.selectors.getValidations);

  const formattedValidations = validations.map(validation => ({
    ...validation,
    startDate: validation.startDate ? formatDate(validation.startDate) : null,
    endDate: validation.endDate ? formatDate(validation.endDate) : null,
    paymentDate: validation.paymentDate
      ? formatDate(validation.paymentDate)
      : null,
  }));

  const totalValidations = useSelector(
    errorLogsActions.selectors.getTotalValidations
  );
  const [edit, setEdit] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [updatedRows, setUpdatedRows] = useState({
    salesData: {},
    validation: {},
  });

  useEffect(() => {
    dispatch({
      type: GET_FILES,
      payload: {
        limit: 100,
        skip: 0,
      },
    });
  }, [dispatch]);
  const files = useSelector(filesActions.selectors.getFiles);
  const selectedFile = useSelector(filesActions.selectors.getSelectedValidFile);
  const [selectedFileId, setSelectedFileId] = useState(
    selectedFile || files?.[0]?.id
  );

  const debouncedFieldChange = useCallback(
    debounce((rowId, errorLogId, field, value) => {
      setUpdatedRows(prev => ({
        salesData: {
          ...prev.salesData,
          [rowId]: {
            ...prev.salesData?.[rowId],
            [field]: value,
          },
        },
        validation: {
          ...prev.validation,
          [errorLogId]: {
            ...prev.validation?.[errorLogId],
            [field]: value,
          },
        },
      }));
    }, 500),
    []
  );
  const handleFieldChange = (rowId, errorLogId, field, value) => {
    debouncedFieldChange(rowId, errorLogId, field, value);
  };

  const handleSelectedFileChange = fileId => {
    setSelectedFileId(fileId);
    dispatch(filesActions.actions.setSelectedValidFile(fileId));
  };

  const columnsWithValidation = useMemo(() => {
    return SALES_COLUMNS.map(col => ({
      ...col,
      renderCell: row => {
        const errorLog = row.ErrorLogs?.find(
          errorLog =>
            errorLog.validationKey === col.field &&
            errorLog?.status === 'Pending'
        );

        return (
          <TextField
            fullWidth
            variant="standard"
            value={
              errorLog &&
              updatedRows?.validation?.[errorLog?.id]?.[col?.field] !==
                undefined
                ? updatedRows?.validation?.[errorLog?.id][col?.field]
                : updatedRows?.salesData?.[row.id]?.[col?.field] !== undefined
                  ? updatedRows?.salesData?.[row?.id][col?.field]
                  : row?.[col?.field] || ''
            }
            error={Boolean(errorLog)}
            helperText={errorLog ? errorLog.errorDescription : ''}
            InputProps={{
              readOnly: !edit,
              disableUnderline: true,
              sx: {
                padding: '5px 0',
                border: 'none',
                fontSize: 'inherit',
                color: 'inherit',
                backgroundColor: errorLog ? '#fdecea' : 'inherit',
                borderColor: errorLog ? '#f44336' : 'inherit',
                width:
                  col?.field == 'paymentDate' ||
                  col?.field == 'startDate' ||
                  col?.field == 'endDate'
                    ? 'max-content'
                    : null,
              },
            }}
            onChange={e =>
              handleFieldChange(row.id, errorLog?.id, col.field, e.target.value)
            }
          />
        );
      },
    }));
  }, [SALES_COLUMNS, updatedRows, handleFieldChange, edit]);

  useEffect(() => {
    if (selectedFileId) {
      dispatch({
        type: FETCH_VALIDATIONS,
        payload: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          selectedFileId,
        },
      });
    }
  }, [dispatch, page, rowsPerPage, selectedFileId]);

  const handleUpdateRow = () => {
    const rowsToUpdate = Object.keys(updatedRows?.salesData).map(rowId => ({
      id: parseInt(rowId),
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      updatedFields: { ...updatedRows.salesData[rowId], status: 'Approved' },
    }));

    const errorLogsToUpdate = Object.keys(updatedRows?.validation).map(
      errorLogId => ({
        id: parseInt(errorLogId),
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        updatedFields: {
          ...updatedRows?.validation[errorLogId],
          status: 'Approved',
        },
      })
    );

    try {
      if (errorLogsToUpdate?.length !== 0) {
        dispatch({
          type: UPDATE_VALIDATION,
          payload: errorLogsToUpdate,
          pagination: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            selectedFileId,
          },
        });
        dispatch({
          type: UPDATE_FILE,
          payload: rowsToUpdate,
        });
        setUpdatedRows({ rows: {}, errorLogs: {} });
        setEdit(false);
      } else {
        toast.error('No Field Changed');
      }
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  const handleEditBtnClick = () => {
    setEdit(true);
  };

  const handleCancelClick = () => {
    setEdit(false);
  };

  return (
    <ValidationTable
      title="Validation Table"
      data={formattedValidations || []}
      columns={columnsWithValidation}
      pageConfig={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows: totalValidations,
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      isEdit={edit}
      onUpdateRows={handleUpdateRow}
      handleEditClick={handleEditBtnClick}
      onCancelClick={handleCancelClick}
      isEditable={true}
      selectedFileId={selectedFileId}
      setSelectedFileId={handleSelectedFileChange}
    />
  );
};

export default Validation;
