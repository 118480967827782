import React, { useEffect, useMemo, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import RefundPaymentRequest from 'components/RefundPaymentRequest/RefundPaymentRequest';
import { useDispatch, useSelector } from 'react-redux';
import { GET_REFUND_PAYMENTS } from '_redux/types/refundpayments.types';
import { refundPaymentActions } from '_redux/slices/refundPayment.slice';
import { GET_REFUND_PAYMENT_BY_ID } from '_redux/types/refundpayments.types';
import { refundColumns } from 'utils/requests';

const Tracking = ({ handleTabChange, tab }) => {
  const dispatch = useDispatch();
  const refundPaymentsRequests = useSelector(
    refundPaymentActions.selectors.getRefundPayments
  );
  const total = useSelector(
    refundPaymentActions.selectors.getTotalRefundPayments
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleOpen = row => {
    dispatch({ type: GET_REFUND_PAYMENT_BY_ID, payload: { id: row?.id } });

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch({
      type: GET_REFUND_PAYMENTS,
      payload: { limit: rowsPerPage, skip: page * rowsPerPage },
    });
  }, [dispatch, page, rowsPerPage]);

  const columns = useMemo(() => refundColumns(handleOpen), [handleOpen]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <GenericTable
        title="Refund Request"
        data={refundPaymentsRequests}
        columns={columns}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: total,
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
        fetchType="refundPayments"
      />
      <ModalContainer open={open}>
        <RefundPaymentRequest handleClose={handleClose} />
      </ModalContainer>
    </>
  );
};

export default Tracking;
