import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { formatToTwoDecimals } from 'utils';
import { formatDate } from 'utils';

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 120px 24px',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          padding: '8px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '5%',
        },
        tableCell_2: {
          width: '50%',
          paddingRight: 16,
        },
        tableCell_3: {
          width: '15%',
        },
      }),
    []
  );

const PdfViewerModal = ({ user, data }) => {
  const styles = useStyles();
  const advisorData = data?.collaborations || [];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>From</Text>
            <Text style={styles.body2}>PayCheck</Text>
            <Text style={styles.body2}>
              {user?.firstName} {user?.lastName}
            </Text>
            <Text style={styles.body2}>{user?.email}</Text>
            <Text style={styles.body2}>{user?.phoneNo}</Text>
          </View>

          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}> To</Text>
            <Text style={styles.body2}>{data?.id}</Text>
            <Text style={styles.body2}>{data?.name}</Text>
            <Text style={styles.body2}>{data?.email}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>Date create</Text>
            <Text style={styles.body2}>{formatDate(new Date())}</Text>
          </View>
        </View>

        <Text style={[styles.subtitle1, styles.mb8]}>Details</Text>

        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Transaction ID</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Client Name</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Income Type</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Plan Type</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Commission</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Date</Text>
              </View>
            </View>
          </View>

          <View>
            {advisorData?.map((item, index) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={styles.tableCell_1}>
                  <Text>{item.id}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item?.transactionId}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item?.clientName}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item?.incomeType}</Text>
                </View>

                <View style={[styles.tableCell_3]}>
                  <Text>{item?.planType}</Text>
                </View>

                <View style={[styles.tableCell_3]}>
                  <Text>£{item?.amount}</Text>
                </View>

                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{formatDate(item?.paymentDate)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.table}>
          <Text style={[styles.subtitle1, styles.mb40]}></Text>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Total Payout</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>£{formatToTwoDecimals(data?.totalPayout) || 0}</Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>{`${data?.adjustments?.[0]?.name} (${data?.adjustments?.[0]?.type})`}</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>
                £{formatToTwoDecimals(data?.adjustments?.[0]?.value) || 0}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text>Frequency</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text>{data?.adjustments?.[0]?.frequency || 'N/A'}</Text>
            </View>
          </View>

          <View style={[styles.tableRow, styles.noBorder]}>
            <View style={styles.tableCell_1} />
            <View style={styles.tableCell_2} />
            <View style={styles.tableCell_3} />
            <View style={styles.tableCell_3}>
              <Text style={styles.h4}>Net Payout</Text>
            </View>
            <View style={[styles.tableCell_3, styles.alignRight]}>
              <Text style={styles.h4}>
                £{formatToTwoDecimals(data?.netPayout) || 0}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfViewerModal;
