import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { ADVISOR_COLUMNS } from 'utils/commission';
import { commissionSplitActions } from '_redux/slices/commissionSplit.slice';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_COMMISSION_SPLITS } from '_redux/types/commissionSplit.types';
import { filesActions } from '_redux/slices/files.slice';

const Advisor = ({ handleTabChange, tab, tabs }) => {
  const dispatch = useDispatch();
  const commissionSplits = useSelector(
    commissionSplitActions.selectors.getCommissionSplits
  );

  const totalCommissionSplits = useSelector(
    commissionSplitActions.selectors.getTotalCommissionSplits
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const files = useSelector(filesActions.selectors.getFiles);
  const selectedFile = useSelector(
    filesActions.selectors.getSelectedAdvisorFile
  );
  const [selectedFileId, setSelectedFileId] = useState(
    selectedFile || files[0]?.id
  );

  useEffect(() => {
    dispatch({
      type: FETCH_COMMISSION_SPLITS,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        id: selectedFileId,
      },
    });
  }, [dispatch, page, rowsPerPage, selectedFileId]);

  const handleSelectedFileChange = fileId => {
    setSelectedFileId(fileId);
    dispatch(filesActions.actions.setSelectedAdvisorFile(fileId));
  };

  return (
    <GenericTable
      title="Commission Split"
      data={commissionSplits}
      columns={ADVISOR_COLUMNS}
      pageConfig={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows: totalCommissionSplits,
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      fetchType="commissions"
      isFile={true}
      selectedFileId={selectedFileId}
      setSelectedFileId={handleSelectedFileChange}
    />
  );
};

export default Advisor;
