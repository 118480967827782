import apiInterceptor from './Interceptors/apiInterceptor';

export const getAllAdvisors = async (pageNumber, pageSize) => {
  try {
    const response = await apiInterceptor.get(
      `api/advisors?page=${pageNumber}&pageSize=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Advisors');
  }
};

export const addAdvisor = async payload => {
  try {
    const response = await apiInterceptor.post(`api/advisors`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to add Advisors');
  }
};

export const editAdvisor = async (id, payload) => {
  try {
    const response = await apiInterceptor.put(`api/advisors/${id}`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to update Advisor');
  }
};

export const getAdvisorById = async id => {
  try {
    const response = await apiInterceptor.get(`api/advisors/${id}`);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Advisor');
  }
};

export const searchAdvisors = async (searchParams, page = 1, pageSize = 10) => {
  try {
    const response = await apiInterceptor.get(
      `/api/advisors?page=${page}&pageSize=${pageSize}&search=${searchParams}`
    );
    return response?.data || [];
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to search Advisors');
  }
};

export const getAdvisorsList = async () => {
  try {
    const response = await apiInterceptor.get(`api/advisors/all`);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Advisors List');
  }
};

export const deleteAdvisors = async (ids) => {
  try {
    const response = await apiInterceptor.delete(
      `api/advisors/multiple`, { data: ids }
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to delete Advisors');
  }
};
