import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';
import styles from './marginChart.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MenuItem, Select } from '@mui/material';
import { FETCH_MARGIN_CHART } from '_redux/types/dashboard.types';
import { selectStyles } from 'utils/styles';
import { EXPORT_MARGIN } from '_redux/types/dashboard.types';
import { Download, Refresh } from '@mui/icons-material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import toast from 'react-hot-toast';
import { GenericTable } from 'components/common/GenericTable';
import { marginColumns } from 'utils/margin';

const MarginChart = () => {
  const dispatch = useDispatch();
  // const advisorChartData = useSelector(
  //   state => state.dashboard?.advisorBase?.advisorBase
  // );
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const total = 0;

  const advisors =
    useSelector(state => state.dashboard?.advisors?.advisors) || [];
  const products =
    useSelector(state => state.dashboard?.productsTypes?.products) || [];

  const comm =
    useSelector(state => state.dashboard?.commissionTypes?.products) || [];

  const margin = useSelector(state => state.dashboard?.margin) || [];

  const allAdvisors = [{ advisorName: 'All Advisors', value: '' }, ...advisors];
  const allProducts = [
    { planType: 'All Product Types', value: '' },
    ...products,
  ];
  const allCommissions = [
    { incomeType: 'All Commission Types', value: '' },
    ...comm,
  ];

  const initialAdvisor = 'All Advisors';
  const initialProduct = 'All Product Types';
  const initialCommission = 'All Commission Types';
  const initialMonth = dayjs();

  const [selectedAdvisor, setSelectedAdvisor] = useState(initialAdvisor);
  const [selectedProduct, setSelectedProduct] = useState(initialProduct);
  const [selectedCommission, setSelectedCommission] =
    useState(initialCommission);

  useEffect(() => {
    const month = selectedMonth.month() + 1;
    const year = selectedMonth.year();

    let advisor = selectedAdvisor;
    let type = selectedProduct;
    let commission = selectedCommission;
    if (selectedAdvisor === initialAdvisor) {
      advisor = '';
    }
    if (type === initialProduct) {
      type = '';
    }
    if (commission == initialCommission) {
      commission = '';
    }
    dispatch({
      type: FETCH_MARGIN_CHART,
      payload: {
        advisor: advisor,
        productType: type,
        commissionType: commission,
        month,
        year,
      },
    });
    dispatch({
      type: EXPORT_MARGIN,
      payload: {
        advisor: advisor,
        productType: type,
        commissionType: commission,
        month,
        year,
      },
    });
  }, [
    dispatch,
    selectedAdvisor,
    selectedProduct,
    selectedCommission,
    selectedMonth,
  ]);

  // const advisorNames = useMemo(
  //   () => advisorChartData?.map(advisor => advisor?.period) || [],
  //   [advisorChartData]
  // );
  // const commissionPercentage = useMemo(
  //   () =>
  //     advisorChartData?.map(advisor =>
  //       Math.ceil(advisor?.commissionPercentage)
  //     ) || [],
  //   [advisorChartData]
  // );
  // const productPercentage = useMemo(
  //   () =>
  //     advisorChartData?.map(advisor => Math.ceil(advisor?.productPercentage)) ||
  //     [],
  //   [advisorChartData]
  // );

  // const series = useMemo(
  //   () => [
  //     {
  //       name: 'Commission Percentage',
  //       data: commissionPercentage,
  //     },
  //   ],
  //   [commissionPercentage, productPercentage]
  // );

  // const colors = ['#FF4560', '#00E396'];

  // const options = useMemo(
  //   () => ({
  //     chart: {
  //       height: 350,
  //       type: 'line',
  //       zoom: {
  //         enabled: false,
  //       },
  //       toolbar: {
  //         show: true,
  //         tools: {
  //           download: false,
  //         },
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       width: 2,
  //       curve: 'smooth',
  //       dashArray: [10, 10],
  //     },
  //     markers: {
  //       size: 5,
  //       colors: colors,
  //       hover: {
  //         size: 7,
  //       },
  //     },
  //     colors: colors,
  //     legend: {
  //       show: true,
  //     },
  //     xaxis: {
  //       categories: advisorNames,
  //     },
  //     yaxis: {
  //       labels: {
  //         formatter: value => `${value}%`,
  //       },
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: value => `${value}%`,
  //       },
  //       marker: {
  //         show: false,
  //         fillColors: colors,
  //       },
  //     },
  //   }),
  //   [advisorNames, colors]
  // );

  const handleAdvisorChange = event => {
    setSelectedAdvisor(event.target.value);
  };

  const handleProductChange = event => {
    setSelectedProduct(event.target.value);
  };

  const handleCommissionChange = event => {
    setSelectedCommission(event.target.value);
  };

  const jsonToCSV = jsonData => {
    const csvRows = [];
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(','));

    jsonData.forEach(row => {
      const values = headers.map(header => {
        const value = row[header];
        return `"${value}"`;
      });
      csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
  };

  const handleExportClick = () => {
    if (margin?.length === 0) {
      toast.error('No Data Found');
    } else {
      const csvData = jsonToCSV(margin);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'margin_data.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  const handleResetFilters = () => {
    setSelectedAdvisor(initialAdvisor);
    setSelectedProduct(initialProduct);
    setSelectedCommission(initialCommission);
    setSelectedMonth(initialMonth);
  };

  const isNotInitialState =
    selectedAdvisor !== initialAdvisor ||
    selectedProduct !== initialProduct ||
    selectedCommission !== initialCommission ||
    !selectedMonth.isSame(initialMonth, 'month');

  const transformedData = margin?.map(item => ({
    advisorName: item['advisorName'],
    month: item['month'],
    productType: item['productType'],
    commissionType: item['monthlyCommission type'],
    totalFCIRecognition: item['totalFCIRecognition']?.toFixed(2),
  }));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div id="chart" className={styles.box}>
        <div className={styles.header}>
          <Select
            value={selectedAdvisor}
            onChange={handleAdvisorChange}
            size="small"
            displayEmpty
            className={styles.dropdown}
            sx={selectStyles}
          >
            {allAdvisors.map((advisor, index) => (
              <MenuItem
                key={index}
                value={advisor.value || advisor.advisorName}
              >
                {advisor.advisorName}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedProduct}
            onChange={handleProductChange}
            size="small"
            displayEmpty
            className={styles.dropdown}
            sx={selectStyles}
          >
            {allProducts.map((product, index) => (
              <MenuItem key={index} value={product.value || product.planType}>
                {product.planType}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedCommission}
            onChange={handleCommissionChange}
            size="small"
            displayEmpty
            className={styles.dropdown}
            sx={selectStyles}
          >
            {allCommissions.map((commission, index) => (
              <MenuItem
                key={index}
                value={commission.value || commission.incomeType}
              >
                {commission.incomeType}
              </MenuItem>
            ))}
          </Select>
          <MobileDatePicker
            open={isPickerOpen}
            onOpen={() => setPickerOpen(true)}
            onClose={() => setPickerOpen(false)}
            views={['year', 'month']}
            label="Select Month"
            minDate={dayjs('2022-01-01')}
            maxDate={dayjs('2030-12-31')}
            value={selectedMonth}
            onChange={newDate => setSelectedMonth(newDate)}
            onAccept={newDate => {
              console.log('ACCEPTED');
              setPickerOpen(false);
            }}
            DialogProps={{
              keepMounted: true,
            }}
            sx={{
              ...selectStyles,
              '& .MuiInputBase-root': {
                height: '40px',
                fontSize: '1rem',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '0.5rem',
              },
              backgroundColor: '#f5f7fa',
            }}
          />
          <Button
            variant="contained"
            startIcon={<Download />}
            onClick={handleExportClick}
            sx={{
              marginLeft: 'auto',
              backgroundColor: '#003366',
              color: '#fff',
            }}
          >
            Export CSV
          </Button>
          {isNotInitialState && (
            <Button
              variant="contained"
              startIcon={<Refresh />}
              onClick={handleResetFilters}
              sx={{
                backgroundColor: '#FF5733',
                color: '#fff',
              }}
            >
              Reset Filters
            </Button>
          )}
        </div>
        <GenericTable
          data={transformedData || []}
          columns={marginColumns}
          isPagination={false}
          isDownload={false}
          pageConfig={{
            page,
            setPage,
            rowsPerPage,
            setRowsPerPage,
            totalRows: total,
          }}
        />
        {/* <Chart options={options} series={series} type="line" height={450} /> */}
      </div>
    </LocalizationProvider>
  );
};

export default MarginChart;
