import React from 'react';
import { motion } from 'framer-motion';
import { popup } from './framer';

const Animation = ({ children, delay = 0 }) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={popup}
      transition={{ duration: 0.5, ease: 'easeInOut', delay }}
    >
      {children}
    </motion.div>
  );
};

export default Animation;
