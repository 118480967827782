import React, { useMemo, useState } from 'react';
import {
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from '@mui/material';
import { Edit, Search } from '@mui/icons-material';
import styles from './genericTable.module.scss';
import Animation from 'utils/Animation';
import { card } from 'utils/styles';
import { uploadButton } from 'utils/styles';
import { icon } from 'utils/styles';
import { Title } from 'components/common/Title';
import LeftToRight from 'utils/LeftToRight';
import { Tabs } from 'components/common/Tabs';
import ColsSettings from './ColsSettings/ColsSettings';
import { ColFilterIcon } from 'assets/svgs';
import { useSelector } from 'react-redux';
import { filesActions } from '_redux/slices/files.slice';

const ValidationTable = ({
  title,
  data,
  columns,
  pageConfig,
  isMain = false,
  isTabs = false,
  handleTabChange,
  tab,
  tabs = [],
  isSearch = true,
  isEdit = false,
  onUpdateRows,
  handleEditClick,
  onCancelClick,
  isEditable = false,
  selectedFileId,
  setSelectedFileId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCols, setSelectedCols] = useState(
    columns.map(col => col.field)
  );
  const { page, setPage, rowsPerPage, setRowsPerPage, totalRows } = pageConfig;
  const [searchQuery, setSearchQuery] = useState('');

  const files = useSelector(filesActions.selectors.getFiles);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenColsSettings = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColsSettings = () => {
    setAnchorEl(null);
  };

  const handleToggleCol = colField => {
    setSelectedCols(prev =>
      prev.includes(colField)
        ? prev.filter(field => field !== colField)
        : [...prev, colField]
    );
  };

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

  const filteredData = useMemo(
    () =>
      data?.filter(row =>
        columns.some(column =>
          String(row[column.field] || '')
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      ),
    [data, columns, searchQuery]
  );

  const filteredColumns = useMemo(
    () => columns.filter(col => selectedCols.includes(col.field)),
    [columns, selectedCols]
  );

  const handleFileSelectionChange = event => {
    const selectedFileId = event.target.value;
    setSelectedFileId(selectedFileId);
  };

  return (
    <Card sx={card}>
      {isSearch ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box className={styles.buttonsBox}>
            <Title title={title} />
            <Box className={styles.buttons}>
              {isEdit ? (
                <Animation>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      borderColor: '#003366',
                      color: '#003366',
                      '&:hover': {
                        borderColor: '#002244',
                        color: 'white',
                        backgroundColor: '#003366',
                      },
                    }}
                    onClick={onCancelClick}
                  >
                    Cancel
                  </Button>
                </Animation>
              ) : null}
              {!isEdit && isEditable ? (
                <Animation>
                  <Button
                    variant="contained"
                    startIcon={<Edit />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#003366',
                      '&:hover': {
                        backgroundColor: '#002244',
                      },
                    }}
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>
                </Animation>
              ) : null}
              {isEdit ? (
                <Animation>
                  <Button
                    variant="contained"
                    startIcon={<Edit />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#003366',
                      '&:hover': {
                        backgroundColor: '#002244',
                      },
                    }}
                    onClick={onUpdateRows}
                  >
                    Save
                  </Button>
                </Animation>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
      {isTabs && tabs.length > 0 ? (
        <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      ) : null}
      <Box sx={uploadButton}>
        {isSearch ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            p={1}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E0E0E0',
                  },
                },
              }}
              sx={{ width: '100%' }}
            />
            <FormControl
              variant="outlined"
              size="small"
              sx={{ minWidth: 120, backgroundColor: '#fff' }}
            >
              <InputLabel id="file-select-label">File</InputLabel>
              <Select
                labelId="file-select-label"
                value={selectedFileId}
                onChange={handleFileSelectionChange}
                label="File"
                sx={{ borderRadius: '0.5rem' }}
              >
                {files?.map((file, index) => (
                  <MenuItem value={file?.id} key={index}>
                    {file?.fileName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton sx={icon} onClick={handleOpenColsSettings}>
              <ColFilterIcon />
            </IconButton>
          </Box>
        ) : null}
        <ColsSettings
          columns={columns}
          selectedCols={selectedCols}
          onToggleCol={handleToggleCol}
          anchorEl={anchorEl}
          handleClose={handleCloseColsSettings}
        />
        <TableContainer className={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {filteredColumns.map((column, index) => (
                  <TableCell
                    key={index}
                    className={styles.tableHeadCell}
                    padding={column.padding || 'normal'}
                  >
                    <Animation delay={index * 0.1}>
                      {column.headerName}
                    </Animation>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: isMain
                      ? row.status === 'Pending'
                        ? '#F5D9D9'
                        : '#ffffff'
                      : index % 2 == 0
                        ? '#ffffff'
                        : '#F9FAFC',
                  }}
                >
                  {filteredColumns.map((column, colIndex) => (
                    <TableCell key={colIndex} className={styles.tableBodyCell}>
                      <LeftToRight index={index}>
                        {column.renderCell
                          ? column.renderCell(row)
                          : row[column.field]}
                      </LeftToRight>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter className={styles.tableFooterRow}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150]}
              colSpan={columns.length}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </Card>
  );
};

export default ValidationTable;
