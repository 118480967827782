import apiInterceptor from './Interceptors/apiInterceptor';

export const getAllCategories = async (pageNumber, pageSize) => {
  try {
    const response = await apiInterceptor.get(
      `api/categories?page=${pageNumber}&pageSize=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Categories');
  }
};

export const getAllPayouts = async (
  pageNumber,
  pageSize,
  selectedMonth,
  selectedMonthTo
) => {
  try {
    const response = await apiInterceptor.get(
      `api/payouts?page=${pageNumber}&pageSize=${pageSize}&selectedMonth=${selectedMonth}&selectedMonthTo=${selectedMonthTo}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Categories');
  }
};

export const getProductCategoryById = async id => {
  try {
    const response = await apiInterceptor.get(`api/categories`, id);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Category');
  }
};

export const createProductCategory = async payload => {
  try {
    const response = await apiInterceptor.post(`api/categories`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to create Category');
  }
};

export const editProductCategory = async (id, payload) => {
  try {
    const response = await apiInterceptor.put(`api/categories/${id}`, payload);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to update Categories');
  }
};

export const deleteProductCategory = async id => {
  try {
    const response = await apiInterceptor.delete(`api/categories/${id}`);
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to delete Category');
  }
};

export const searchProduct = async (searchParams, page = 1, pageSize = 10) => {
  try {
    const response = await apiInterceptor.get(
      `api/categories?page=${page}&pageSize=${pageSize}&search=${searchParams}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to search Categories');
  }
};

export const searchPayouts = async (
  searchParams,
  page = 1,
  pageSize = 10,
  selectedMonth,
  selectedMonthTo
) => {
  try {
    const response = await apiInterceptor.get(
      `api/payouts?page=${page}&pageSize=${pageSize}&search=${searchParams}&selectedMonth=${selectedMonth}&selectedMonthTo=${selectedMonthTo}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to search Categories');
  }
};
