import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchErrorLogsService = async ({
  limit,
  skip,
  selectedFileId,
  search = ''
}) => {
  try {
    const response = await apiInterceptor.get(
      `/api/errorLogs/${selectedFileId}?limit=${limit}&skip=${skip}&search=${search}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch error logs failed');
  }
};

export const updateValidationService = async (updatedData, id) => {
  try {
    const response = await apiInterceptor.put(
      `/api/errorLogs/${id}?limit=${updatedData?.[0]?.limit}&skip=${updatedData?.[0]?.skip}`,
      {
        data: updatedData,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const fetchValidationLogsService = async ({
  limit,
  skip,
  selectedFileId,
}) => {
  try {
    const response = await apiInterceptor.post(
      `/api/errorLogs/validation/${selectedFileId}?limit=${limit}&skip=${skip}`
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || 'Fetch validation logs failed'
    );
  }
};
