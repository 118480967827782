import React, { useCallback, useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { getAdvisorColumns } from 'utils/Advisors';
import AddNewIntroducer from 'components/AddNewIntroducer/AddNewIntroducer';
import AddNewAdvisor from 'components/AddNewAdvisor/AddNewAdvisor';
import { Toaster, toast } from 'react-hot-toast';
import { getAllAdvisors } from '_redux/services/advisor.service';
import { editAdvisor } from '_redux/services/advisor.service';

const AdvisorStatement = ({ handleTabChange, tab, tabs, advisorFields }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [isIntroducerModalOpen, setisIntroducerModalOpen] = useState(false);
  const [isAdvisorModalOpen, setisAdvisorModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [updatedRow, setUpdatedRow] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const getAdvisorsData = useCallback(async () => {
    try {
      const response = await getAllAdvisors(page, rowsPerPage);
      const total = response.total;
      setTotalData(total);

      setData(prevData => {
        const lastPage = Math.ceil(total / rowsPerPage) - 1;
        if (page === lastPage) {
          const remainingItems = total % rowsPerPage || rowsPerPage;
          return response.data.slice(0, remainingItems);
        }
        return response.data;
      });
    } catch (error) {
      console.error('Error fetching advisors:', error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    getAdvisorsData();
  }, [getAdvisorsData]);

  const handleRowSelection = id => {
    setSelectedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
    setCheckedAll(false);
  };

  const handleSaveChanges = async () => {
    try {
      const newRow = await editAdvisor(updatedRow.id, updatedRow.valuesChanged);

      if (newRow.name) {
        toast.success('Row updated successfully!');
        handleEditToggle();
        getAdvisorsData();
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleValueChange = (id, field, value) => {
    setData(prevData =>
      prevData.map(row => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleAdvisorClick = () => {
    setisAdvisorModalOpen(true);
  };

  const handleIntroducerClick = () => {
    setisIntroducerModalOpen(true);
  };

  const handleAdvisorModalClose = () => {
    setisAdvisorModalOpen(false);
  };

  const handleModalClose = () => {
    setisIntroducerModalOpen(false);
  };

  const handleEditToggle = () => {
    setIsEditMode(prev => !prev);
  };
  const handelCancelEdit = () => {
    setIsEditMode(false);
    getAdvisorsData();
    setIsDelete(false);
    setSelectedRows([]);
  };

  const handleUpdateRow = row => {
    setUpdatedRow(row);
  };

  // const filteredData = useMemo(() => {
  //   return data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  // }, [data, page, rowsPerPage]);

  const handleAllChecked = () => {
    const allIds = data?.map(row => row.id) || [];
    const allSelected =
      allIds.length > 0 && allIds.every(id => selectedRows.includes(id));

    if (allSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allIds);
    }
  };

  const columns = getAdvisorColumns({
    data,
    selectedRows,
    setSelectedRows,
    checkedAll,
    setCheckedAll,
    handleAllChecked,
    handleRowSelection,
    handleValueChange,
    isEditMode,
    handleUpdateRow,
    advisorFields,
  });

  useEffect(() => {
    const allIds = data?.map(row => row.id) || [];
    const allSelected =
      allIds.length > 0 && allIds.every(id => selectedRows.includes(id));
    setCheckedAll(allSelected);
    if (selectedRows.length > 0) {
      setIsDelete(true);
    } else {
      setIsDelete(false);
    }
  }, [selectedRows, data]);

  return (
    <>
      <Toaster />
      <GenericTable
        title="Set-up"
        addNewIntroducer={true}
        addNewAdvisor={true}
        refreshData={getAdvisorsData}
        isEditMode={isEditMode}
        showEdit={true}
        handleEditToggle={handleEditToggle}
        handleCancelEdit={handelCancelEdit}
        handleSaveChanges={handleSaveChanges}
        columns={columns}
        data={data}
        setData={setData}
        isAdvisor={true}
        fetchType="advisors"
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalData,
        }}
        handleTabChange={handleTabChange}
        onAddIntroducer={handleIntroducerClick}
        onAddAdvisor={handleAdvisorClick}
        tab={tab}
        isTabs={true}
        tabs={tabs}
        isDelete={isDelete}
        ids={selectedRows}
        handleDeleted={handelCancelEdit}
      />
      {isIntroducerModalOpen && (
        <AddNewIntroducer
          onClose={handleModalClose}
          open={isIntroducerModalOpen}
          handleClose={handleModalClose}
          refreshData={getAdvisorsData}
        />
      )}
      {isAdvisorModalOpen && (
        <AddNewAdvisor
          onClose={handleAdvisorModalClose}
          open={isAdvisorModalOpen}
          handleClose={handleAdvisorModalClose}
          refreshData={getAdvisorsData}
        />
      )}
    </>
  );
};

export default AdvisorStatement;
