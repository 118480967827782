import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchAllCommissionsService = async () => {
  try {
    const response = await apiInterceptor.post(`/api/commissionSplits/all`);
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || 'Fetch commission splits failed'
    );
  }
};

export const fetchAllpayoutService = async ({ period = 'weekly' }) => {
  try {
    const response = await apiInterceptor.post(
      `/api/payouts/all?period=${period}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch payouts failed');
  }
};

export const fetchAllErrorLogsService = async (selectedFileId = 1) => {
  try {
    const response = await apiInterceptor.post(
      `/api/errorLogs/all/${selectedFileId}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch error logs failed');
  }
};

export const fetchAllEarlyPayService = async () => {
  try {
    const response = await apiInterceptor.post(`/api/earlyPayment/all`);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch payments failed');
  }
};

export const fetchAllRefundPayService = async () => {
  try {
    const response = await apiInterceptor.post(`/api/refundPayment/all`);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch refunds failed');
  }
};
