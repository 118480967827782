import React, { useCallback, useMemo, useState } from 'react';
import {
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  TextField,
  Popover,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  CloudUpload,
  Download,
  Edit,
  Save,
  Cancel,
  CheckCircleOutlineOutlined,
  Description,
  Delete,
} from '@mui/icons-material';
import styles from './genericTable.module.scss';
import Animation from 'utils/Animation';
import { card } from 'utils/styles';
import { uploadButton } from 'utils/styles';
import { icon } from 'utils/styles';
import { Title } from 'components/common/Title';
import LeftToRight from 'utils/LeftToRight';
import { Tabs } from 'components/common/Tabs';
import ColsSettings from './ColsSettings/ColsSettings';
import { ColFilterIcon } from 'assets/svgs';
import { ArrowDropDownIcon, MobileDatePicker } from '@mui/x-date-pickers';
import { fetchAllCommissionsService } from '_redux/services/allData.service';
import { fetchAllErrorLogsService } from '_redux/services/allData.service';
import { fetchAllEarlyPayService } from '_redux/services/allData.service';
import { fetchAllRefundPayService } from '_redux/services/allData.service';
import { fetchAllpayoutService } from '_redux/services/allData.service';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { debounce } from 'lodash';
import { filesActions } from '_redux/slices/files.slice';
import { useSelector } from 'react-redux';
import { searchAdvisors } from '_redux/services/advisor.service';
import { searchIntroducers } from '_redux/services/introducer.service';
import { searchProduct } from '_redux/services/categories.service';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { searchCollaborators } from '_redux/services/collaboration.service';
import { searchAdjustmentTypes } from '_redux/services/adjustmentTypes.service';
import { searchAdjustments } from '_redux/services/adjustments.service';
import { searchPayouts } from '_redux/services/categories.service';
import { getCollaborationData } from '_redux/services/collaboration.service';
import { getAllAdjustments } from '_redux/services/adjustments.service';
import { getAllAdjustmentTypes } from '_redux/services/adjustmentTypes.service';
import { getAllIntroducers } from '_redux/services/introducer.service';
import { getAllAdvisors } from '_redux/services/advisor.service';
import { getAllPayouts } from '_redux/services/categories.service';
import { getAllCategories } from '_redux/services/categories.service';
import { deleteAdjustments } from '_redux/services/adjustments.service';
import { deleteCollaborations } from '_redux/services/collaboration.service';
import { deleteAdjustmentTypes } from '_redux/services/adjustmentTypes.service';
import { deleteAdvisors } from '_redux/services/advisor.service';
import { deleteIntroducers } from '_redux/services/introducer.service';
import toast from 'react-hot-toast';
import 'dayjs/locale/en-gb'; // Import UK locale which starts week on Monday
import updateLocale from 'dayjs/plugin/updateLocale';
import { getFilesService } from '_redux/services/files.service';
import { fetchFileDataService } from '_redux/services/files.service';
import { fetchErrorLogsService } from '_redux/services/errorLogs.service';

dayjs.extend(updateLocale);
dayjs.locale('en-gb');
dayjs.updateLocale('en-gb', {
  weekStart: 1,
});

const GenericTable = ({
  title,
  data = [],
  columns,
  pageConfig,
  onUploadClick,
  isMain = false,
  isTabs = false,
  handleTabChange,
  tab,
  tabs = [],
  isUpload = false,
  isSearch = true,
  isEdit = false,
  isRefundRequest = false,
  addNewIntroducer = false,
  addNewAdvisor = false,
  isPayRequest = false,
  onEarlyPayRequest,
  onRefundRequest,
  onAddAdvisor,
  onAddIntroducer,
  onUpdateRows,
  period,
  setPeriod,
  isPeriod = false,
  fetchType = 'commissions',
  isDownload = true,
  isPagination = true,
  onAddExpense,
  isAddExpense = false,
  isDate = false,
  date,
  handleCancelEdit,
  handleEditToggle,
  handleSaveChanges,
  isEditMode,
  showEdit,
  handleEditClick,
  onCancelClick,
  isEditable = false,
  selectedRows,
  setSelectedRows,
  isFile = false,
  selectedFileId,
  setSelectedFileId,
  isReport = false,
  onShareReport,
  isAdvisorsTable,
  addNewProduct,
  onAddNewProduct,
  setData,
  isIntroducer,
  isAdvisor,
  isCategory,
  refreshData,
  isCollaboration,
  isAdjustmentTypes,
  handleModalOpen,
  isAdjustments,
  isPayouts = false,
  setSelectedMonth,
  selectedMonth,
  setSelectedMonthTo,
  selectedMonthTo,
  isDelete = false,
  ids = [],
  handleDeleted,
  isUploads = false,
  isUploadFiles = false,
  isErrors = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [isPickerOpen1, setPickerOpen1] = useState(false);
  const files = useSelector(filesActions.selectors.getFiles);
  const [focusedField, setFocusedField] = useState(null);
  const [originalData, setOriginalData] = useState(data);

  const [selectedCols, setSelectedCols] = useState(
    columns.map(col => col.field)
  );

  const [splitTypeFilters, setSplitTypeFilters] = useState({
    Advisor: false,
    SplitPartner: false,
  });

  // const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectRow = row => {
    setSelectedRows(prev =>
      prev.some(selected => selected.id === row.id)
        ? prev.filter(selected => selected.id !== row.id)
        : [...prev, row]
    );
  };

  const handleSelectRow2 = row => {
    setSelectedRows(prev =>
      prev.some(selected => selected.advisorId === row.advisorId)
        ? prev.filter(selected => selected.advisorId !== row.advisorId)
        : [...prev, row]
    );
  };

  const { page, setPage, rowsPerPage, setRowsPerPage, totalRows } = pageConfig;

  const [updatedRows, setUpdatedRows] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  // const [selectedMonth, setSelectedMonth] = useState(dayjs());

  const handlePopoverOpen = event => {
    setAnchorEl1(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl1(null);
  };

  const isPopoverOpen = Boolean(anchorEl1);

  const handleFilterChange = filter => {
    setSplitTypeFilters(prev => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  const handleSaveClick = () => {
    const rowsToUpdate = Object.keys(updatedRows).map(rowId => ({
      id: parseInt(rowId),
      updatedFields: updatedRows[rowId],
    }));

    if (onUpdateRows && rowsToUpdate.length > 0) {
      onUpdateRows(rowsToUpdate);
      setUpdatedRows({});
    }
  };

  const debouncedFieldChange = useCallback(
    debounce((rowId, field, value) => {
      setUpdatedRows(prev => ({
        ...prev,
        [rowId]: {
          ...prev[rowId],
          [field]: value !== undefined ? value : '',
        },
      }));
    }, 1),
    []
  );

  const handleFieldChange = (rowId, field, value) => {
    debouncedFieldChange(rowId, field, value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenColsSettings = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColsSettings = () => {
    setAnchorEl(null);
  };

  const handleToggleCol = colField => {
    setSelectedCols(prev =>
      prev.includes(colField)
        ? prev.filter(field => field !== colField)
        : [...prev, colField]
    );
  };

  const fetchSearchResults = useCallback(
    debounce(async query => {
      if (!query) {
        refreshData();
        return;
      }
      try {
        const results =
          (await (isUploads &&
            (await getFilesService({ limit: 100, skip: 0, search: query })))) ||
          (await (isUploadFiles &&
            (await fetchFileDataService(selectedFileId, query)))) ||
          (await (isErrors &&
            (await fetchErrorLogsService({
              limit: 100,
              skip: 0,
              selectedFileId,
              search: query,
            })))) ||
          (await (isAdvisor &&
            (await searchAdvisors(query, page, rowsPerPage)))) ||
          (isIntroducer &&
            (await searchIntroducers(query, page, rowsPerPage))) ||
          (isCategory && (await searchProduct(query, page, rowsPerPage))) ||
          (isCollaboration &&
            (await searchCollaborators(query, page, rowsPerPage))) ||
          (isAdjustmentTypes &&
            (await searchAdjustmentTypes(query, page, rowsPerPage))) ||
          (isAdjustments &&
            (await searchAdjustments(query, page, rowsPerPage))) ||
          (isPayouts &&
            (await searchPayouts(
              query,
              page,
              rowsPerPage,
              selectedMonth,
              selectedMonthTo
            )));
        if (isErrors) {
          setData(results.data.ErrorLogs);
        } else if (isUpload) {
          setData(results.data.upload);
        } else if (results) {
          setData(results.data);
        }
      } catch (error) {
        console.error('Search failed:', error);
      }
    }, 500),
    []
  );

  const handleSearchChange = event => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query && !originalData) {
      // Added condition to set originalData only if query exists and originalData is null
      setOriginalData(data);
    }
    fetchSearchResults(query);
  };

  // const filteredData = useMemo(
  //   () =>
  //     data
  //       .filter(row => {
  //         const splitType = row.splitType;
  //         if (splitTypeFilters.Advisor && splitType === 'Adviser') return true;
  //         if (splitTypeFilters.SplitPartner && splitType === 'Split Partner')
  //           return true;
  //         return !splitTypeFilters.Advisor && !splitTypeFilters.SplitPartner;
  //       })
  //       .filter(row =>
  //         columns.some(column => {
  //           const cellValue =
  //             column.field === 'date'
  //               ? formatDate(row[column.field])
  //               : row[column.field];
  //           return String(cellValue || '')
  //             .toLowerCase()
  //             .includes(searchQuery.toLowerCase());
  //         })
  //       ),
  //   [data, columns, searchQuery, splitTypeFilters]
  // );

  const filteredColumns = useMemo(
    () => columns?.filter(col => selectedCols?.includes(col.field)),
    [columns, selectedCols, data]
  );

  const handlePeriodChange = event => {
    setPeriod(event.target.value);
  };
  const flattenData = data => {
    return data.map(item => ({
      // Flattening the fields from the parent object
      id: item.id,
      transactionID: item.transactionID,
      errorDescription: item.errorDescription,
      date: item.date,
      status: item.status,
      comment: item.comment,
      errorLocation: item.errorLocation,
      validationKey: item.validationKey,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      saleDataId: item.saleDataId,
      uploadId: item.uploadId,

      // Flattening the SalesDatum fields
      ...(item.SalesDatum && {
        salesTransactionID: item.SalesDatum.transactionID,
        clientName: item.SalesDatum.clientName,
        advisorName: item.SalesDatum.advisorName,
        advisorId: item.SalesDatum.advisorId,
        salesStartDate: item.SalesDatum.startDate,
        salesEndDate: item.SalesDatum.endDate,
        salesPaymentDate: item.SalesDatum.paymentDate,
        planType: item.SalesDatum.planType,
        plzNumber: item.SalesDatum.plzNumber,
        frequency: item.SalesDatum.frequency,
        grossFCI: item.SalesDatum.grossFCI,
        FCIRecognition: item.SalesDatum.FCIRecognition,
        provider: item.SalesDatum.provider,
        premium: item.SalesDatum.premium,
        payable: item.SalesDatum.payable,
        percentagePayable: item.SalesDatum.percentagePayable,
        cashType: item.SalesDatum.cashType,
        incomeType: item.SalesDatum.incomeType,
        cashMatchId: item.SalesDatum.cashMatchId,
        salesCreatedAt: item.SalesDatum.createdAt,
        salesUpdatedAt: item.SalesDatum.updatedAt,
        salesUploadId: item.SalesDatum.uploadId,
      }),
    }));
  };

  const flattenAdjustment = data => {
    return data.map(item => ({
      id: item.id,
      IOB: item.IOB,
      createdAt: item.createdAt,
      effectiveFrom: item.effectiveFrom,
      effectiveTo: item.effectiveTo,
      frequency: item.frequency,
      note: item.note,
      rules: item.rules,
      value: item.value,
      description: item.description,

      ...(item.type && {
        adjustmentType: item.type.title,
        type: item.type.type,
      }),

      ...(item.advisors && {
        advisors: item.advisors?.[0].name,
      }),
    }));
  };

  const flattenStatement = data => {
    return data.map(item => ({
      id: item.id,
      name: item.name,
      netPayout: item.netPayout,
      totalPayout: item.totalPayout,

      ...(item.adjustments && {
        frequency: item.adjustments?.[0].frequency,
        value: item.adjustments?.[0]?.value,
        type: item.adjustments?.[0].type,
        adjustment: item.adjustments?.[0].name,
      }),
    }));
  };

  const transformData = data => {
    return data.map(item => ({
      ...item,
      productType: item.productType.join(', '),
      incomeType: item.incomeType.join(', '),
    }));
  };

  const fetchAllData = async () => {
    try {
      let response;
      switch (fetchType) {
        case 'collaborations':
          response = await getCollaborationData(1, 10000);
          response = response?.data;
          break;
        case 'adjustments':
          response = await getAllAdjustments(1, 10000);
          response = flattenAdjustment(response?.data);
          break;
        case 'adjustmentsTypes':
          response = await getAllAdjustmentTypes(1, 10000);
          response = response?.data;
          break;
        case 'errorLogs':
          response = await fetchAllErrorLogsService(selectedFileId);
          response = flattenData(response?.data?.ErrorLogs);
          break;
        case 'introducers':
          response = await getAllIntroducers(1, 10000);
          response = response?.data;
          break;
        case 'advisors':
          response = await getAllAdvisors(1, 10000);
          response = response?.data;
          break;
        case 'statements':
          response = await getAllPayouts(
            1,
            10000,
            selectedMonth,
            selectedMonthTo
          );
          response = flattenStatement(response?.data);
          break;
        case 'category':
          response = await getAllCategories(1, 10000);
          response = transformData(response?.data);
          break;
        default:
          throw new Error('Invalid fetch type');
      }
      return response || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleDelete = async () => {
    try {
      let response;
      switch (fetchType) {
        case 'collaborations':
          response = await deleteCollaborations({ ids });
          response = response?.data;
          break;
        case 'adjustments':
          response = await deleteAdjustments({ ids });
          break;
        case 'adjustmentsTypes':
          response = await deleteAdjustmentTypes({ ids });
          response = response?.data;
          break;
        case 'introducers':
          response = await deleteIntroducers({ ids });
          response = response?.data;
          break;
        case 'advisors':
          response = await deleteAdvisors({ ids });
          response = response?.data;
          break;
        default:
          throw new Error('Invalid delete type');
      }
      toast.success('Rows deleted successfully!');
      handleDeleted();
      return response || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleExportCSV = async () => {
    const allData = await fetchAllData();
    const csvData = allData?.map(row =>
      columns.reduce((acc, column) => {
        acc[column.field] = row[column.field];
        return acc;
      }, {})
    );
    const csvRows = [
      columns.map(col => col.headerName).join(','),
      ...csvData.map(row =>
        columns.map(col => JSON.stringify(row[col.field] || '')).join(',')
      ),
    ];
    const csvBlob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = `${title || 'table_data'}.csv`;
    link.click();
  };

  const handleAddExpenseClick = () => {
    if (onAddExpense) {
      onAddExpense(selectedRows);
    }
  };

  const handleShareReport = () => {
    if (isReport) {
      onShareReport(selectedRows);
    }
  };

  const handleFileSelectionChange = event => {
    const selectedFileId = event.target.value;
    setSelectedFileId(selectedFileId);
  };

  const handleFocus = (rowId, field) => {
    setFocusedField({ rowId, field });
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const clearSearch = () => {
    // Added clearSearch function
    setSearchQuery('');
    // if (originalData) {
    //   setData(originalData)
    //   setOriginalData(null)
    // } else {
    refreshData();
    // }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Card sx={card}>
        {isSearch ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box className={styles.buttonsBox}>
              <Title title={title} />
              <Box className={styles.buttons}>
                {isUpload ? (
                  <Animation>
                    <Button
                      variant="contained"
                      startIcon={<CloudUpload />}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#003366',
                        '&:hover': {
                          backgroundColor: '#002244',
                        },
                      }}
                      onClick={onUploadClick}
                    >
                      Upload
                    </Button>
                  </Animation>
                ) : null}
                {isAddExpense && selectedRows.length > 0 ? (
                  <Animation>
                    <Button
                      variant="contained"
                      // startIcon={<CloudUpload />}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#003366',
                        '&:hover': {
                          backgroundColor: '#002244',
                        },
                      }}
                      onClick={handleAddExpenseClick}
                    >
                      Add Expense
                    </Button>
                  </Animation>
                ) : null}
                {isReport && selectedRows.length > 0 ? (
                  <Animation>
                    <Button
                      variant="contained"
                      // startIcon={<CloudUpload />}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#003366',
                        '&:hover': {
                          backgroundColor: '#002244',
                        },
                      }}
                      onClick={handleShareReport}
                    >
                      Share
                    </Button>
                  </Animation>
                ) : null}
                {isRefundRequest ? (
                  <Animation>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#FFCE00',
                        color: 'black',
                        '&:hover': {
                          backgroundColor: '#002244',
                          color: 'white',
                        },
                      }}
                      onClick={() => onRefundRequest()}
                    >
                      Refund Request
                    </Button>
                  </Animation>
                ) : null}
                {isPayRequest ? (
                  <Animation>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#FFCE00',
                        color: 'black',
                        '&:hover': {
                          backgroundColor: '#002244',
                          color: 'white',
                        },
                      }}
                      onClick={() => onEarlyPayRequest()}
                    >
                      Early Payment Request
                    </Button>
                  </Animation>
                ) : null}
                {addNewIntroducer && !isEditMode && tab == 0 ? (
                  <Animation>
                    <Button
                      variant="outlined"
                      type="primary"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#FFD700',
                        color: '#003466',
                        border: '1px solid #FFD700',
                        padding: '8px 16px',
                        '&:hover': {
                          backgroundColor: '#003366',
                          border: '1px solid #003366',
                          color: 'white',
                        },
                      }}
                      onClick={() => onAddIntroducer()}
                    >
                      + Add New Introducer
                    </Button>
                  </Animation>
                ) : null}
                {addNewAdvisor && !isEditMode && tab == 1 ? (
                  <Animation>
                    <Button
                      variant="contained"
                      type="primary"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        border: '1px solid #003366',
                        backgroundColor: 'white',
                        color: '#003366',
                        padding: '8px 16px',
                        marginRight: '15px',
                        '&:hover': {
                          backgroundColor: '#002244',
                          color: 'white',
                        },
                      }}
                      onClick={() => onAddAdvisor()}
                    >
                      + Add New Advisor
                    </Button>
                  </Animation>
                ) : null}

                {addNewProduct ? (
                  <Animation>
                    <Button
                      variant="contained"
                      type="primary"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        border: '1px solid #003366',
                        backgroundColor: 'white',
                        color: '#003366',
                        padding: '8px 16px',
                        marginRight: '15px',
                        '&:hover': {
                          backgroundColor: '#002244',
                          color: 'white',
                        },
                      }}
                      onClick={() => onAddNewProduct()}
                    >
                      + Add New Product
                    </Button>
                  </Animation>
                ) : null}

                {isEdit ? (
                  <Animation>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        borderColor: '#003366',
                        color: '#003366',
                        '&:hover': {
                          borderColor: '#002244',
                          color: 'white',
                          backgroundColor: '#003366',
                        },
                      }}
                      onClick={onCancelClick}
                    >
                      Cancel
                    </Button>
                  </Animation>
                ) : null}
                {!isEdit && isEditable ? (
                  <Animation>
                    <Button
                      variant="contained"
                      startIcon={<Edit />}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#003366',
                        '&:hover': {
                          backgroundColor: '#002244',
                        },
                      }}
                      onClick={handleEditClick}
                    >
                      Edit
                    </Button>
                  </Animation>
                ) : null}
                {isAdjustmentTypes && !isEditMode ? (
                  <Animation>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#003366',
                        '&:hover': {
                          backgroundColor: '#002244',
                        },
                      }}
                      onClick={handleModalOpen}
                    >
                      Set-up Adjustments
                    </Button>
                  </Animation>
                ) : (
                  ''
                )}
                {isAdjustments && !isEditMode ? (
                  <Animation>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        backgroundColor: '#003366',
                        '&:hover': {
                          backgroundColor: '#002244',
                        },
                      }}
                      onClick={handleModalOpen}
                    >
                      Add Adjustments
                    </Button>
                  </Animation>
                ) : (
                  ''
                )}
                {isEditMode || isDelete ? (
                  <Animation>
                    <Button
                      startIcon={<Cancel />}
                      variant="outlined"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        borderColor: '#003366',
                        color: '#003366',
                        '&:hover': {
                          borderColor: '#002244',
                          color: 'white',
                          backgroundColor: '#003366',
                        },
                      }}
                      onClick={handleCancelEdit}
                    >
                      Cancel
                    </Button>
                  </Animation>
                ) : null}
                {isDelete ? (
                  <Animation>
                    <Button
                      startIcon={<Delete />}
                      variant="outlined"
                      sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                        borderColor: '#003366',
                        color: '#003366',
                        '&:hover': {
                          borderColor: '#002244',
                          color: 'white',
                          backgroundColor: '#003366',
                        },
                      }}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Animation>
                ) : null}
                {isEdit ? (
                  <>
                    <Animation>
                      <Button
                        variant="contained"
                        startIcon={<Edit />}
                        sx={{
                          textTransform: 'none',
                          borderRadius: '8px',
                          backgroundColor: '#003366',
                          '&:hover': {
                            backgroundColor: '#002244',
                          },
                        }}
                        onClick={handleSaveClick}
                      >
                        Save
                      </Button>
                    </Animation>
                  </>
                ) : null}

                {showEdit ? (
                  <>
                    <Animation>
                      <Button
                        variant="contained"
                        startIcon={
                          isEditMode ? (
                            isAdjustments ? (
                              <CheckCircleOutlineOutlined />
                            ) : (
                              <Save />
                            )
                          ) : (
                            <Edit />
                          )
                        }
                        sx={{
                          textTransform: 'none',
                          borderRadius: '8px',
                          backgroundColor: '#003366',
                          '&:hover': {
                            backgroundColor: '#002244',
                          },
                        }}
                        onClick={
                          isEditMode ? handleSaveChanges : handleEditToggle
                        }
                      >
                        {isEditMode
                          ? isAdjustments
                            ? 'Apply Adjustments'
                            : 'Save'
                          : 'Edit'}
                      </Button>
                    </Animation>
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        ) : null}
        {isTabs && tabs.length > 0 ? (
          <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
        ) : null}
        <Box sx={uploadButton}>
          {isSearch ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              p={1}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  endAdornment: searchQuery && (
                    <IconButton size="small" onClick={clearSearch}>
                      <ClearIcon />
                    </IconButton>
                  ),
                  sx: {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E0E0E0',
                    },
                  },
                }}
                sx={{ width: '100%' }}
              />
              {isPeriod ? (
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{
                    minWidth: 120,
                    backgroundColor: '#fff',
                  }}
                >
                  <InputLabel id="period-select-label">Period</InputLabel>
                  <Select
                    labelId="period-select-label"
                    value={period}
                    onChange={handlePeriodChange}
                    label="Period"
                    sx={{
                      borderRadius: '0.5rem',
                    }}
                  >
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                  </Select>
                </FormControl>
              ) : null}
              {isDate ? (
                <>
                  <MobileDatePicker
                    open={isPickerOpen}
                    onOpen={() => setPickerOpen(true)}
                    onClose={() => setPickerOpen(false)}
                    views={['year', 'month', 'day']}
                    label="Select From"
                    minDate={dayjs('2022-01-01')}
                    maxDate={dayjs('2030-12-31')}
                    value={selectedMonth}
                    onChange={newDate => setSelectedMonth(newDate)}
                    onAccept={newDate => {
                      // date(newDate);
                      setSelectedMonth(newDate);
                      setPickerOpen(false);
                    }}
                    DialogProps={{
                      keepMounted: true,
                    }}
                    sx={{
                      borderRadius: '0.5rem',
                      '& .MuiInputBase-root': {
                        height: '40px',
                        fontSize: '1rem',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                      },
                      backgroundColor: 'white',
                    }}
                  />
                  <MobileDatePicker
                    open={isPickerOpen1}
                    onOpen={() => setPickerOpen1(true)}
                    onClose={() => setPickerOpen1(false)}
                    views={['year', 'month', 'day']}
                    label="Select To"
                    minDate={dayjs('2022-01-01')}
                    maxDate={dayjs('2030-12-31')}
                    value={selectedMonthTo}
                    onChange={newDate => setSelectedMonthTo(newDate)}
                    onAccept={newDate => {
                      // date(newDate);
                      setSelectedMonthTo(newDate);
                      setPickerOpen1(false);
                    }}
                    DialogProps={{
                      keepMounted: true,
                    }}
                    sx={{
                      borderRadius: '0.5rem',
                      '& .MuiInputBase-root': {
                        height: '40px',
                        fontSize: '1rem',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '0.5rem',
                      },
                      backgroundColor: 'white',
                    }}
                  />
                </>
              ) : null}
              {isFile ? (
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 120, backgroundColor: '#fff' }}
                >
                  <InputLabel id="file-select-label">File</InputLabel>
                  <Select
                    labelId="file-select-label"
                    value={selectedFileId}
                    onChange={handleFileSelectionChange}
                    label="File"
                    sx={{ borderRadius: '0.5rem' }}
                  >
                    {files?.map((file, index) => (
                      <MenuItem value={file?.id} key={index}>
                        {file?.fileName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              {isDownload ? (
                <IconButton sx={icon} onClick={handleExportCSV}>
                  <Download />
                </IconButton>
              ) : null}
              <IconButton sx={icon} onClick={handleOpenColsSettings}>
                <ColFilterIcon />
              </IconButton>
            </Box>
          ) : null}
          <ColsSettings
            columns={columns}
            selectedCols={selectedCols}
            onToggleCol={handleToggleCol}
            anchorEl={anchorEl}
            handleClose={handleCloseColsSettings}
          />
          <TableContainer className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {isAddExpense ? (
                    <TableCell
                      padding="checkbox"
                      sx={{ backgroundColor: '#F4F7FC' }}
                    >
                      <Checkbox
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < data.length
                        }
                        checked={
                          data.length > 0 && selectedRows.length === data.length
                        }
                        onChange={() => {
                          setSelectedRows(
                            selectedRows.length === data.length ? [] : data
                          );
                        }}
                      />
                    </TableCell>
                  ) : null}
                  {isReport ? (
                    <TableCell
                      padding="checkbox"
                      sx={{ backgroundColor: '#F4F7FC' }}
                    >
                      <Checkbox
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < data.length
                        }
                        checked={
                          data.length > 0 && selectedRows.length === data.length
                        }
                        onChange={() => {
                          setSelectedRows(
                            selectedRows.length === data.length ? [] : data
                          );
                        }}
                      />
                    </TableCell>
                  ) : null}
                  {filteredColumns.map((column, index) => (
                    <TableCell
                      key={index}
                      className={styles.tableHeadCell}
                      padding={column.padding || 'normal'}
                      onClick={
                        column.headerName === 'Split Type'
                          ? handlePopoverOpen
                          : null
                      }
                    >
                      <Animation delay={index * 0.05}>
                        {column.headerName}
                        {column.headerName === 'Split Type' && (
                          <IconButton size="small" style={{ marginLeft: 4 }}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        )}
                      </Animation>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      backgroundColor: isMain
                        ? row.status === 'Pending'
                          ? '#F5D9D9'
                          : '#ffffff'
                        : index % 2 == 0
                          ? '#ffffff'
                          : '#F9FAFC',
                    }}
                  >
                    {isAddExpense ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.some(
                            selected => selected.id === row.id
                          )}
                          onChange={() => handleSelectRow(row)}
                        />
                      </TableCell>
                    ) : null}
                    {isReport ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.some(
                            selected => selected.advisorId === row.advisorId
                          )}
                          onChange={() => handleSelectRow2(row)}
                        />
                      </TableCell>
                    ) : null}
                    {filteredColumns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        className={styles.tableBodyCell}
                      >
                        <LeftToRight index={index}>
                          {isEdit && column.editAble ? (
                            <TextField
                              fullWidth
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  backgroundColor:
                                    focusedField?.rowId === row.id &&
                                    focusedField?.field === column.field
                                      ? '#e6ecf4'
                                      : 'transparent',
                                  border:
                                    focusedField?.rowId === row.id &&
                                    focusedField?.field === column.field
                                      ? '1px solid #003466'
                                      : 'none',
                                  fontSize: 'inherit',
                                  color: 'inherit',
                                  transition: 'background-color 0.3s ease',
                                },
                              }}
                              onFocus={() => handleFocus(row.id, column.field)}
                              onBlur={handleBlur}
                              value={
                                updatedRows[row.id]?.[column.field] !==
                                undefined
                                  ? updatedRows[row.id][column.field]
                                  : row[column.field] || ''
                              }
                              onChange={e => {
                                const inputValue = e.target.value;
                                handleFieldChange(
                                  row.id,
                                  column.field,
                                  inputValue
                                );
                              }}
                            />
                          ) : column.renderCell ? (
                            column.renderCell(row)
                          ) : (
                            row[column.field]
                          )}
                        </LeftToRight>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Popover
            open={isPopoverOpen}
            anchorEl={anchorEl1}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={splitTypeFilters.Advisor}
                    onChange={() => handleFilterChange('Advisor')}
                  />
                }
                label="Adviser"
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={splitTypeFilters.SplitPartner}
                    onChange={() => handleFilterChange('SplitPartner')}
                  />
                }
                label="Split Partner"
              />
            </Box>
          </Popover>
          {isPagination ? (
            <TableFooter className={styles.tableFooterRow}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[100, 150, 200, 250]}
                  colSpan={columns.length}
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          ) : null}
        </Box>
      </Card>
    </LocalizationProvider>
  );
};

export default GenericTable;
