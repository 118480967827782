import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Chip,
} from '@mui/material';
import styles from './addAdjustments.module.scss';
import { Close } from '@mui/icons-material';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Toaster, toast } from 'react-hot-toast';
import { addAdjustments } from '_redux/services/adjustments.service';
import { getAdvisorsList } from '_redux/services/advisor.service';
// import { getIntroducersList } from '_redux/services/introducer.service';
import { getAdjustmentTypesList } from '_redux/services/adjustmentTypes.service';
import CloseIcon from '@mui/icons-material/Close';
import 'dayjs/locale/en-gb'; // Import UK locale which starts week on Monday
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.locale('en-gb');
dayjs.updateLocale('en-gb', {
  weekStart: 1,
});

const AddAdjustments = ({ handleClose, onClose, open, refreshData }) => {
  const [formValues, setFormValues] = useState({
    advisors: [],
    // introducers: [],
    adjustmentType: '',
    description: '',
    value: '',
    frequency: 'Single',
    effectiveFrom: null,
    effectiveTo: null,
    rules: '',
    note: '',
  });

  const [advisorsList, setAdvisorsList] = useState([]);
  // const [introducersList, setIntroducersList] = useState([]);
  const [adjustmentsList, setAdjustmentsList] = useState([]);

  const fetchAdvisorsList = async () => {
    const advisorsData = await getAdvisorsList();
    setAdvisorsList(advisorsData);
  };
  // const fetchIntroducersList = async () => {
  //   const introducersData = await getIntroducersList();
  //   // setIntroducersList(introducersData);
  // };
  const fetchAdjustmentsList = async () => {
    const adjustmentsTypesData = await getAdjustmentTypesList();
    setAdjustmentsList(adjustmentsTypesData);
  };

  useEffect(() => {
    fetchAdvisorsList();
    // fetchIntroducersList();
    fetchAdjustmentsList();
  }, []);

  const [formErrors, setFormErrors] = useState({
    advisors: false,
    // introducers: false,
    adjustmentType: false,
    value: false,
    frequency: false,
    effectiveFrom: false,
    effectiveTo: false,
    rules: false,
    note: false,
  });

  const handleSubmit = e => {
    e.preventDefault();

    const {
      advisors,
      // introducers,
      adjustmentType,
      value,
      frequency,
      effectiveFrom,
      effectiveTo,
    } = formValues;

    const errors = {};

    if (!advisors.length) errors.advisors = true;
    // if (!introducers.length) errors.introducers = true;
    if (!adjustmentType) errors.adjustmentType = true;
    if (!frequency) errors.frequency = true;
    if (!value) errors.value = true;
    // if (!effectiveFrom || effectiveFrom === null) errors.effectiveFrom = true;
    // if (!effectiveTo || effectiveTo === null) errors.effectiveTo = true;

    if (frequency === 'Ongoing' && (!effectiveFrom || effectiveFrom === null)) {
      errors.effectiveFrom = true;
    }
    if (frequency === 'Ongoing' && (!effectiveTo || effectiveTo === null)) {
      errors.effectiveTo = true;
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      sendDatatoAPI();
    }
  };

  const handleFormChange = e => {
    setFormErrors({ ...formErrors, [e.target.name]: false });
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const sendDatatoAPI = async () => {
    try {
      await addAdjustments(formValues);
      toast.success('Adjustments added successfully!');
      onClose();
      refreshData();
    } catch (error) {
      toast.error(`Error: ${error || 'Something went wrong.'}`);
    }
  };

  const handleEffectiveFromDateChange = date => {
    setFormErrors({ ...formErrors, effectiveFrom: false });
    setFormValues({
      ...formValues,
      effectiveFrom: date || null,
    });
  };

  const handleEffectiveToDateChange = date => {
    setFormErrors({ ...formErrors, effectiveTo: false });
    setFormValues({
      ...formValues,
      effectiveTo: date || null,
    });
  };

  //   const handleValueChange = e => {
  //     setFormErrors({ ...formErrors, value: false });
  //     setFormValues({ ...formValues, [e.target.name]: e.target.value });
  //   };

  const handleAdvisorsChange = e => {
    setFormErrors({ ...formErrors, advisors: false });
    setFormValues({
      ...formValues,
      advisors: [...new Set([...formValues.advisors, e.target.value])],
    });
  };

  // const handleIntroducersChange = e => {
  //   setFormErrors({ ...formErrors, introducers: false });
  //   setFormValues({
  //     ...formValues,
  //     introducers: [...new Set([...formValues.introducers, e.target.value])],
  //   });
  // };

  const handleDeleteAdvisor = id => {
    setFormValues(prevValues => ({
      ...prevValues,
      advisors: prevValues.advisors.filter(advisorId => advisorId !== id),
    }));
  };

  // const handleDeleteIntroducer = id => {
  //   setFormValues(prevValues => ({
  //     ...prevValues,
  //     introducers: prevValues.introducers.filter(
  //       introducerId => introducerId !== id
  //     ),
  //   }));
  // };

  return (
    <>
      <Toaster />
      <ModalContainer open={open}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <Box className={styles.earlyPaymentContainer}>
            <Box className={styles.heading}>
              <Typography variant="h6" className={styles.title}>
                Add Adjustments
              </Typography>
              <IconButton onClick={handleClose} className={styles.closeButton}>
                <Close />
              </IconButton>
            </Box>

            <Box className={styles.formContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    select
                    fullWidth
                    label={`Advisors*`}
                    variant="outlined"
                    name={'advisors'}
                    // value={formValues?.advisors[0]}
                    onChange={handleAdvisorsChange}
                    error={formErrors.advisors}
                    helperText={
                      formErrors.advisors ? 'This field is required' : ''
                    }
                  >
                    {advisorsList?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  {formValues?.advisors?.map(item => (
                    <Chip
                      key={item}
                      label={
                        advisorsList.find(advisor => advisor.id === item)
                          ?.name || item
                      }
                      onDelete={() => handleDeleteAdvisor(item)}
                      deleteIcon={<CloseIcon />}
                    />
                  ))}
                </Grid>

                {/* <Grid item xs={12} md={12}>
                  <TextField
                    select
                    fullWidth
                    label={`Introducer*`}
                    variant="outlined"
                    name={'introducers'}
                    // value={'Select Introducers'}
                    onChange={handleIntroducersChange}
                    error={formErrors.introducers}
                    helperText={
                      formErrors.introducers ? 'This field is required' : ''
                    }
                  >
                    {introducersList?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  {formValues?.introducers?.map(item => (
                    <Chip
                      key={item}
                      label={
                        introducersList.find(
                          introducer => introducer.id === item
                        )?.name || item
                      }
                      onDelete={() => handleDeleteIntroducer(item)}
                      deleteIcon={<CloseIcon />}
                    />
                  ))}
                </Grid> */}
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="IOB"
                    variant="outlined"
                    name={'IOB'}
                    value={formValues.IOB}
                    onChange={handleFormChange}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    select
                    fullWidth
                    label={`Adjustment*`}
                    variant="outlined"
                    name={'adjustmentType'}
                    value={formValues.adjustmentType}
                    onChange={handleFormChange}
                    error={formErrors.adjustmentType}
                    helperText={
                      formErrors.adjustmentType ? 'This field is required' : ''
                    }
                  >
                    {adjustmentsList?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    variant="outlined"
                    name={'description'}
                    value={formValues.description}
                    onChange={handleFormChange}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Value*"
                    variant="outlined"
                    name={'value'}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={formValues.value}
                    onChange={handleFormChange}
                    className={styles.textField}
                    error={formErrors.value}
                    helperText={
                      formErrors.value ? 'This field is required' : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    select
                    fullWidth
                    label={`Frequency*`}
                    variant="outlined"
                    name={'frequency'}
                    value={formValues.frequency}
                    onChange={handleFormChange}
                    error={formErrors.frequency}
                    helperText={
                      formErrors.frequency ? 'This field is required' : ''
                    }
                  >
                    <MenuItem value="Single">Single</MenuItem>
                    <MenuItem value="Ongoing">Ongoing</MenuItem>
                  </TextField>
                </Grid>
                {formValues.frequency === 'Ongoing' && (
                  <>
                    <Grid item xs={12} md={12}>
                      <MobileDatePicker
                        label="Effective From*"
                        sx={{ width: '-webkit-fill-available' }}
                        value={formValues.effectiveFrom}
                        views={['year', 'month', 'day']}
                        minDate={dayjs('2022-01-01')}
                        maxDate={dayjs('2030-12-31')}
                        onChange={handleEffectiveFromDateChange}
                        slots={{
                          textField: props => (
                            <TextField
                              {...props}
                              error={formErrors.effectiveFrom}
                              helperText={
                                formErrors.effectiveFrom
                                  ? 'This field is required'
                                  : ''
                              }
                            />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MobileDatePicker
                        label="Effective To*"
                        sx={{ width: '-webkit-fill-available' }}
                        value={formValues.effectiveTo}
                        views={['year', 'month', 'day']}
                        minDate={dayjs('2022-01-01')}
                        maxDate={dayjs('2030-12-31')}
                        onChange={handleEffectiveToDateChange}
                        slots={{
                          textField: props => (
                            <TextField
                              {...props}
                              error={formErrors.effectiveTo}
                              helperText={
                                formErrors.effectiveTo
                                  ? 'This field is required'
                                  : ''
                              }
                            />
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    rows={4}
                    label={'Notes'}
                    variant="outlined"
                    name={'note'}
                    value={formValues.note}
                    onChange={handleFormChange}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Rules"
                    variant="outlined"
                    name={'rules'}
                    value={formValues.rules}
                    onChange={handleFormChange}
                    className={styles.textField}
                  />
                </Grid>
              </Grid>

              <Box className={styles.actionButtons}>
                <Button
                  variant="outlined"
                  className={styles.rejectButton}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={styles.acceptButton}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </ModalContainer>
    </>
  );
};

export default AddAdjustments;
