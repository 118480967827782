import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import styles from './payouts.module.scss';
import { GenericTable } from 'components/common/GenericTable';
import { getAllPayouts } from '_redux/services/categories.service';
import { getPayoutsColumns } from 'utils/Payouts';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer'; // Importing PDF utilities
import PdfViewerModal from 'components/PdfViewerModal/PdfViewerModal';
import { authActions } from '_redux/slices/auth.slice';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const Payouts = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [advisor, setAdvisor] = useState(null);

  const { user } = useSelector(authActions.selectors.getUser);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedMonthTo, setSelectedMonthTo] = useState(dayjs());

  const getCategoriesData = useCallback(async () => {
    const response = await getAllPayouts(
      page,
      rowsPerPage,
      selectedMonth.format('YYYY-MM-DD'),
      selectedMonthTo.format('YYYY-MM-DD')
    );
    const total = response.total;
    setTotalData(total);

    const lastPage = Math.ceil(total / rowsPerPage) - 1;
    if (page === lastPage) {
      const remainingItems = total % rowsPerPage || rowsPerPage;
      setData(response.data.slice(0, remainingItems));
    } else {
      setData(response.data);
    }
  }, [page, rowsPerPage, selectedMonth, selectedMonthTo]);

  useEffect(() => {
    getCategoriesData();
  }, [getCategoriesData]);

  const handleView = row => {
    setAdvisor(row);
    openPdfInNewTab(row);
  };

  const openPdfInNewTab = row => {
    // Generate PDF with @react-pdf/renderer
    const pdfDoc = pdf(<PdfViewerModal user={user} data={row} />);

    // Get the generated PDF as a Blob
    pdfDoc.toBlob().then(blob => {
      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(blob);

      // Open a new tab and display the PDF
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.title = 'Payout Details';
        newTab.document.body.innerHTML = `
          <div id="pdf-viewer-container" style="height: 100%; width: 100%; overflow: hidden;">
            <iframe src="${pdfUrl}" width="100%" height="100%" style="border: none;"></iframe>
          </div>
        `;
      }
    });
  };

  const columns = getPayoutsColumns({ onView: handleView });

  return (
    <>
      <Box className={styles.tableContainer}>
        <Grid container>
          <Grid m={4} item xs={12}>
            <GenericTable
              title={'Statements'}
              refreshData={getCategoriesData}
              setData={setData}
              pageConfig={{
                page,
                setPage,
                rowsPerPage,
                setRowsPerPage,
                totalRows: totalData,
              }}
              addNewProduct={false}
              onAddNewProduct={() => {}}
              columns={columns}
              data={data}
              isPayouts={true}
              isDate={true}
              fetchType="statements"
              setSelectedMonth={setSelectedMonth}
              selectedMonth={selectedMonth}
              selectedMonthTo={selectedMonthTo}
              setSelectedMonthTo={setSelectedMonthTo}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Payouts;
