import React, { useCallback, useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { getCollaboratorColumns } from 'utils/collaboration';
import { getCollaborationData } from '_redux/services/collaboration.service';
import { updateCollaborationData } from '_redux/services/collaboration.service';
import { Toaster, toast } from 'react-hot-toast';

const Split = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [updatedRow, setUpdatedRow] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const getCollaboratiorsList = useCallback(async () => {
    try {
      const response = await getCollaborationData(page, rowsPerPage);
      const total = response.total;
      setTotalData(total);

      setData(prevData => {
        const lastPage = Math.ceil(total / rowsPerPage) - 1;
        if (page === lastPage) {
          const remainingItems = total % rowsPerPage || rowsPerPage;
          return response.data.slice(0, remainingItems);
        }
        return response.data;
      });
    } catch (error) {
      console.error('Error fetching advisors:', error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    getCollaboratiorsList();
  }, [getCollaboratiorsList]);

  const handleRowSelection = id => {
    setSelectedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
    setCheckedAll(false);
  };

  const handleValueChange = (id, field, value) => {
    setData(prevData =>
      prevData.map(row => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleEditToggle = () => {
    setIsEditMode(prev => !prev);
  };
  const handelCancelEdit = () => {
    setIsEditMode(false);
    setIsDelete(false);
    setSelectedRows([]);
    getCollaboratiorsList();
  };

  const handleSaveChanges = async () => {
    try {
      const newRow = await updateCollaborationData(
        updatedRow.id,
        updatedRow.valuesChanged
      );

      if (newRow.id) {
        toast.success('Row updated successfully!');
        handleEditToggle();
        getCollaboratiorsList();
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleUpdateRow = row => {
    setUpdatedRow(row);
  };

  const handleAllChecked = () => {
    const allIds = data?.map(row => row.id) || [];
    const allSelected =
      allIds.length > 0 && allIds.every(id => selectedRows.includes(id));

    if (allSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allIds);
    }
  };

  const columns = getCollaboratorColumns({
    data,
    selectedRows,
    setSelectedRows,
    checkedAll,
    setCheckedAll,
    handleAllChecked,
    handleRowSelection,
    handleValueChange,
    isEditMode,
    handleUpdateRow,
  });

  useEffect(() => {
    const allIds = data?.map(row => row.id) || [];
    const allSelected =
      allIds.length > 0 && allIds.every(id => selectedRows.includes(id));
    setCheckedAll(allSelected);

    if (selectedRows.length > 0) {
      setIsDelete(true);
    } else {
      setIsDelete(false);
    }
  }, [selectedRows, data]);

  return (
    <>
      <Toaster />
      <GenericTable
        title="Collaborations"
        data={data}
        columns={columns}
        isCollaboration={true}
        refreshData={getCollaboratiorsList}
        handleEditToggle={handleEditToggle}
        handleCancelEdit={handelCancelEdit}
        handleSaveChanges={handleSaveChanges}
        isEditMode={isEditMode}
        showEdit={true}
        isDelete={isDelete}
        setData={setData}
        fetchType="collaborations"
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalData,
        }}
        ids={selectedRows}
        handleDeleted={handelCancelEdit}
      />
    </>
  );
};

export default Split;
