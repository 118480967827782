import React, { useCallback, useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { Toaster, toast } from 'react-hot-toast';
import './advisorAdjustments.module.scss';
import { getAllAdjustments } from '_redux/services/adjustments.service';
import { editAdjustments } from '_redux/services/adjustments.service';
import { getAdjustmentsColumns } from 'utils/Adjustments';
import AddAdjustments from 'components/AddAdjustments/AddAdjustments';

const AdvisorAdjustments = ({ handleTabChange, tab, tabs }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState();
  const [isAddAdjustment, setIsAddAdjustment] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [updatedRow, setUpdatedRow] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const getAdjustmentsData = useCallback(async () => {
    try {
      const response = await getAllAdjustments(page, rowsPerPage);
      const total = response.total;
      setTotalData(total);

      setData(prevData => {
        const lastPage = Math.ceil(total / rowsPerPage) - 1;
        if (page === lastPage) {
          const remainingItems = total % rowsPerPage || rowsPerPage;
          return response.data.slice(0, remainingItems);
        }
        return response.data;
      });
    } catch (error) {
      console.error('Error fetching Adjustments:', error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    getAdjustmentsData();
  }, [getAdjustmentsData]);

  const handleRowSelection = id => {
    setSelectedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
    setCheckedAll(false);
  };

  const handleValueChange = (id, field, value) => {
    setData(prevData =>
      prevData.map(row => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleModalOpen = () => {
    setIsAddAdjustment(true);
  };

  const handleModalClose = () => {
    setIsAddAdjustment(false);
  };

  const handleEditToggle = () => {
    setIsEditMode(prev => !prev);
  };
  const handelCancelEdit = () => {
    setIsEditMode(false);
    setIsDelete(false);
    setSelectedRows([]);
    getAdjustmentsData();
  };

  const handleSaveChanges = async () => {
    try {
      const newRow = await editAdjustments(
        updatedRow.id,
        updatedRow.valuesChanged
      );

      if (newRow.id) {
        toast.success('Row updated successfully!');
        handleEditToggle();
        getAdjustmentsData();
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleUpdateRow = row => {
    setUpdatedRow(row);
  };

  const handleAllChecked = () => {
    const allIds = data?.map(row => row.id) || [];
    const allSelected =
      allIds.length > 0 && allIds.every(id => selectedRows.includes(id));

    if (allSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allIds);
    }
  };

  const columns = getAdjustmentsColumns({
    data,
    selectedRows,
    setSelectedRows,
    checkedAll,
    setCheckedAll,
    handleAllChecked,
    handleRowSelection,
    handleValueChange,
    isEditMode,
    handleUpdateRow,
  });

  useEffect(() => {
    const allIds = data?.map(row => row.id) || [];
    const allSelected =
      allIds.length > 0 && allIds.every(id => selectedRows.includes(id));
    setCheckedAll(allSelected);

    if (selectedRows.length > 0) {
      setIsDelete(true);
    } else {
      setIsDelete(false);
    }
  }, [selectedRows, data]);

  return (
    <>
      <Toaster />
      <GenericTable
        refreshData={getAdjustmentsData}
        title="Adjustments"
        handleEditToggle={handleEditToggle}
        handleCancelEdit={handelCancelEdit}
        handleSaveChanges={handleSaveChanges}
        isEditMode={isEditMode}
        showEdit={true}
        fetchType="adjustments"
        columns={columns}
        data={data}
        setData={setData}
        isAdjustments={true}
        handleModalOpen={handleModalOpen}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalData,
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={true}
        tabs={tabs}
        isDelete={isDelete}
        ids={selectedRows}
        handleDeleted={handelCancelEdit}
      />

      {isAddAdjustment && (
        <AddAdjustments
          onClose={handleModalClose}
          open={isAddAdjustment}
          handleClose={handleModalClose}
          refreshData={getAdjustmentsData}
        />
      )}
    </>
  );
};

export default AdvisorAdjustments;
