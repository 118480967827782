import apiInterceptor from './Interceptors/apiInterceptor';

export const getCollaborationData = async (page, rowsPerPage) => {
  try {
    const response = await apiInterceptor.get(
      `api/collaboration?page=${page}&pageSize=${rowsPerPage}`
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to get Collaborators');
  }
};

export const updateCollaborationData = async (id, payload) => {
  try {
    const response = await apiInterceptor.put(
      `api/collaboration/${id}`,
      payload
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to update Collaborators');
  }
};

export const searchCollaborators = async (
  searchParams,
  page = 1,
  pageSize = 10
) => {
  try {
    const response = await apiInterceptor.get(
      `/api/collaboration?page=${page}&pageSize=${pageSize}&search=${searchParams}`
    );
    return response?.data || [];
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to search Collaborators');
  }
};

export const deleteCollaborations = async (ids) => {
  try {
    const response = await apiInterceptor.delete(
      `api/collaboration/multiple`, { data: ids }
    );
    return response?.data;
  } catch (error) {
    const message = JSON.stringify(error?.response?.data?.error);
    const err = message?.replace(/[\\"]/g, '');
    throw new Error(`${err}` || 'Failed to delete Collaborators');
  }
};